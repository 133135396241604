import axios from "axios";

//const usersApiBaseUrl = "https://localhost:44373";
const usersApiBaseUrl = "https://usersV2.nvapps.novatronica.com";

export function login(credentials = { username: "", password: "" }) {
    return axios({
        method: "POST",
        url: `${usersApiBaseUrl}/api/login`,
        data: credentials
    });
}

export function forgotPassword(forgotPasswordForm = { username: "" }) {
    return axios({
        method: "POST",
        url: `${usersApiBaseUrl}/api/forgotPassword`,
        data: forgotPasswordForm
    });
}

export function passwordRecovery(passwordRecoveryForm = { username: "", recoveryCode: "", password: "" }) {
    return axios({
        method: "POST",
        url: `${usersApiBaseUrl}/api/forgotPassword/recover`,
        data: passwordRecoveryForm
    });
}

export function confirmDeleteAccount(deleteForm = { id: "", code: "" }) {
    return axios({
        method: "POST",
        url: `${usersApiBaseUrl}/api/unregister/confirm`,
        data: deleteForm
    });
}

export function register(registerForm = {
    username: "",
    password: "",
    name: "",
    country: "",
    language: "",
    newsletter: false,
    appToken: ""
}) {
    return axios({
        method: "POST",
        url: `${usersApiBaseUrl}/api/register`,
        data: registerForm
    });
}

export function getProfile(jwtToken) {
    return axios({
        method: "GET",
        url: `${usersApiBaseUrl}/api/profile`,
        headers: { Authorization: `Bearer ${jwtToken}` }
    });
}

export function updateProfile(updateProfileForm = {
    username: "user@example.com",
    name: "",
    country: "",
    language: "",
    newsletter: true
}, jwtToken) {
    return axios({
        method: "POST",
        url: `${usersApiBaseUrl}/api/profile`,
        headers: { Authorization: `Bearer ${jwtToken}` },
        data: updateProfileForm
    });
}

export function changePassword(changePasswordForm = { newPassword: "" }, jwtToken) {
    return axios({
        method: "POST",
        url: `${usersApiBaseUrl}/api/profile/changePassword`,
        headers: { Authorization: `Bearer ${jwtToken}` },
        data: changePasswordForm
    });
}